import { initializeApp } from "firebase/app";
import { persistentLocalCache, initializeFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAVYsp0BOawV8u5i0q_qBZzuwSTz0NpAJ4",
  projectId: "seowizardtracker",
  messagingSenderId: "1021492342790",
  appId: "1:1021492342790:web:73cdc48210978cee345387",
  measurementId: "G-4C0EMWYW5N",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = initializeFirestore(app, {
  localCache: persistentLocalCache()
});
const analytics = getAnalytics(app);

export { db, analytics };
