import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

import ReactPaginate from "react-paginate";
export const formatData = (data) => {
  return new Date(data).toLocaleDateString("en-GB").replace(/\//g, "/");
};
const BlogGridOne = (props) => {
  const [blogs, setBlogData] = useState(props.blogData);

  useEffect(() => {
    setBlogData(props.blogData);
  }, [props.blogData]);

  const [pageNumber, setPageNumber] = useState(0);

  const blogsPerPage = 10;
  const pageVisited = pageNumber * blogsPerPage;

  const pageCount = Math.ceil(blogs.length / blogsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <>
      {blogs.slice(pageVisited, pageVisited + blogsPerPage).map((data) => (
        <div className="blog-grid" key={data.id}>
          <h3 className="title">
            <Link to={process.env.PUBLIC_URL + `/blog/${data.id}`}>
              {data.title}
            </Link>
          </h3>
          <div className="author">
            <div className="author-thumb"></div>
            <div className="info">
              <h6 className="author-name">
                נכתב על ידי : <strong>{data.author}</strong>
              </h6>
              <ul className="blog-meta list-unstyled">
                <li>{formatData(data.created_at)}</li>
                <li>תגיות: {data.tags.join(",")}</li>
              </ul>
            </div>
          </div>
          <p>{data.shortDescription}</p>

          <Link
            className="axil-btn btn-borderd btn-large"
            to={process.env.PUBLIC_URL + `/blog/${data.id}`}
          >
            קרא עוד
          </Link>
        </div>
      ))}

      <ReactPaginate
        previousLabel={<FaArrowLeft />}
        nextLabel={<FaArrowRight />}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"pagination justify-content-start"}
        previousLinkClassName={"prev"}
        nextLinkClassName={"next"}
        disabledClassName={"disabled"}
        activeClassName={"current"}
      />
    </>
  );
};

export default BlogGridOne;
