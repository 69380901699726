import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import Markdown from "react-markdown";
import { fetchBlogById } from "../api/db/blogs";
import { useNavigate } from "react-router-dom";

export const formatData = (data) => {
  return new Date(data).toLocaleDateString("en-GB").replace(/\//g, "/");
};

const BlogDetails = () => {
  const params = useParams();
  const blogId = params.id;
  const navigate = useNavigate();

  const [data, setBlogData] = useState();

  useEffect(() => {
    const s = async () => {
      let blogData = await fetchBlogById(blogId);
      if (blogData) {
        blogData.content = blogData.content.replaceAll("#newline#", "\n");
        setBlogData(blogData);
      } else {
        setBlogData(null);
        navigate("/");
      }
    };
    s();
  }, [params]);

  return (
    <>
      {data && (
        <>
          {" "}
          <SEO title={data.title} />
          <ColorSwitcher />
          <main className="main-wrapper">
            <HeaderOne />
            <BreadCrumbOne title={data.title} page="מאמר" />
            <div className="section-padding-equal">
              <div className="container">
                <div className="row row-40">
                  <div className="col-lg-8">
                    <div className="single-blog">
                      <div className="single-blog-content blog-grid">
                        <div className="author">
                          <div className="info">
                            <h6 className="author-name">
                              נכתב על ידי : <strong>{data.author}</strong>
                            </h6>
                            <ul className="blog-meta list-unstyled">
                              <li>
                                תאריך כתיבה:{" "}
                                <strong>{formatData(data.created_at)}</strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <Markdown>{data.content}</Markdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <CtaLayoutOne />
            <FooterOne parentClass="" />
          </main>
        </>
      )}
    </>
  );
};

export default BlogDetails;
