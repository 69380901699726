import React, { useEffect, useState } from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import BlogGridOne from "../component/blog/BlogGridOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import { fetchBlogs } from "../api/db/blogs";

const BlogGridView = () => {
  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    const s = async () => {
      setBlogData(await fetchBlogs());
    };
    s();
    console.log(blogData);

  }, []);
  return (
    <>
      <SEO title="Blog Grid" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="מאמרים" page="מאמר" />
        <div className="section-padding-equal">
          <div className="container">
            <div className="row row-40">
              <div className="col-lg-8">
                <BlogGridOne blogData={blogData} />
              </div>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default BlogGridView;
