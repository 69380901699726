import React from "react";
import { Link } from "react-router-dom";

import ServiceData from "../../data/service/ServiceMain.json";
import { slugify } from "../../utils";

const getServiceData = ServiceData;

const FooterOne = ({ parentClass }) => {
  return (
    <footer className={`footer-area ${parentClass}`} id="contact">
      <div className="container">
        <div className="footer-top"></div>
        <div className="footer-main">
          <div className="row">
            <div className="col-xl-6 col-lg-5">
              <div className="footer-widget border-end">
                <div className="footer-newsletter">
                  <h2 className="title">צרו איתנו קשר</h2>
                  <p>אנחנו זמינים לכם לכל שאלה , בעיה ועזרה</p>
                  <h5>weseo.supp@gmail.com</h5>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="row">
                <div className="col-sm-6">
                  <div className="footer-widget">
                    <h6 className="widget-title">שירותים</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled" role="menu">
                        {getServiceData.slice(0, 6).map((data, index) => (
                          <li key={index}>
                            <Link to={slugify(data.title)}>{data.title}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footer-widget">
                    <h6 className="widget-title">כללי</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled">
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/blog"}>
                            מאמרים
                          </Link>
                        </li>
                        <li>
                          <a href={"https://gencv.app"} target="_blank">
                            GenCV | יצירת קורות חיים
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="footer-widget">
                    <h6 className="widget-title">תמיכה</h6>
                    <div className="footer-menu-link">
                      <ul className="list-unstyled" role="menu">
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/contact"}>
                            צרו קשר
                          </Link>
                        </li>
                        <li>
                          {" "}
                          <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
                            מדיניות פרטיות
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/terms-use"}>
                            תנאי שימוש
                          </Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/terms-use"}>
                            הצהרת נגישות
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-copyright">
                <span className="copyright-text">
                  © {new Date().getFullYear()}. כל הזכויות שמורות WiseFox
                  Technologies.
                </span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-bottom-link">
                <ul className="list-unstyled">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>
                      מדיניות פרטיות
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/terms-use"}>
                      תנאי שימוש
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
