import React, { useState } from "react";
import TestimonialData from "../../data/testimonial/TestimonialData.json";
import compe from "./assets/compe.png";
import stats from "./assets/stats.png";
import words from "./assets/words.png";
import reports from "./assets/reports.png";
import you from "./assets/you.png";
import icon1 from "./assets/icon-1.png";
import icon2 from "./assets/icon-2.png";
import icon3 from "./assets/icon-3.png";
import icon4 from "./assets/icon-4.png";
import icon5 from "./assets/icon-5.png";

import "./index.scss";
const allData = TestimonialData;
const titles = [
  { id: 0, value: "מעקב מתחרים", data: "מעקב מתחרים מאפשר להוסיף אתרים רלוונטיים ולנתח את מיקומיהם בגוגל ביחס למילות המפתח שאתם מקדמים, מה שמספק תמונת מצב ברורה על הביצועים שלכם לעומת המתחרים ומאפשר זיהוי הזדמנויות לשיפור.", picture: compe, icon: icon5 },
  {
    id: 1,
    value: "מעקב מילות חיפוש",
    data: "המערכת אוספת נתונים בזמן אמת על מילות המפתח שלכם, כולל דירוג נוכחי, שינויים ביחס לעדכון הקודם, ומגמות שיפור או ירידה. כך תוכלו לעקוב בקלות אחר ההתקדמות שלכם ולזהות הזדמנויות לשיפור.",
    picture: words,
    icon: icon4,
  },
  { id: 2, value: "דוחות", data: "המערכת מפיקה דוחות מותאמים אישית שמציגים את מיקומי מילות המפתח, מצב טכני של האתר כולל דומיין ו-SSL, והמלצות ברורות לשיפור הביצועים והחשיפה בגוגל", picture: reports, icon: icon1 },
  { id: 3, value: "סטטיסטיקות", data: "ניתן לצפות בנתונים סטטיסטיים מרכזיים, כמו ממוצע מיקומי מילות המפתח שלכם ותצוגה גרפית של מגמות לאורך זמן. כל זה עוזר לכם להבין את המצב הנוכחי שלכם ואת ההתקדמות הכללית בקידום האתר.", picture: stats, icon: icon3 },
  { id: 4, value: "אתה מול המתחרים", data: "אחת התכונות הבולטות היא האפשרות להשוות את הדירוגים שלכם למתחרים על מילות מפתח מסוימות. תוכלו לראות בגרפים ברור את מיקומכם ביחס למתחרים לאורך זמן, ולהשתמש בנתונים כדי לתכנן את אסטרטגיית הקידום הבאה שלכם.", picture: you, icon: icon5 },
];
const TestimonialItem = ({ colSize }) => {
  const [current, setCurrent] = useState(1);
  return (
    <>
      {
        <>
          <div key={1} className="whatAreWeDoing">
            {titles.map((title, index) => (
              <div
                key={index}
                onClick={() => setCurrent(title.id)}
                className="iconTitleHeader"
              >
                <div className="icon">
                  <img src={title.icon} alt={title.data} />
                </div>
                <div className="icontitle">{title.value}</div>
              </div>
            ))}
          </div>
          <div className="whatData">
            <div className="text">{titles[current].data}</div>
            <div className="whatImg">
              <img src={titles[current].picture} alt={titles[current].data} />
            </div>
          </div>
        </>
      }
    </>
  );
};

export default TestimonialItem;
