import React from "react";
import { Link } from "react-scroll";
import { Link as LinkDom } from "react-router-dom";

const Nav = () => {
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li>
          <LinkDom to={process.env.PUBLIC_URL + "/"} role="button">
            דף הבית
          </LinkDom>
        </li>
        <li>
          <Link to="services" role="button">
            השירותים שלנו
          </Link>
        </li>
        <li>
          <Link to="recom" role="button">
            ממליצים
          </Link>
        </li>
        <li>
          <Link to="stats" role="button">
            סטטיסטיקות
          </Link>
        </li>
        <li>
          <Link to="prices" role="button">
            מחירים
          </Link>
        </li>
        <li>
          <Link to="contact" role="button">
            צרו קשר
          </Link>
        </li>
        <li>
          <a
            href={"https://weseo.app/blog"}
            role="button"
            aria-label="נפתח באותו הטאב"
          >
            מאמרים
          </a>
        </li>
        <li>
          <a
            href={"https://dashboard.weseo.app/"}
            target="_blank"
            role="button"
            aria-label="נפתח בטאב חדש"
          >
            התחברות
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
