import {
  collection,
  doc,
  getDocs,
  getDoc,
  query,
  orderBy,
  limit,
  where,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";

/**
 * @typedef {Object} Blog
 * @property {string} id - The unique identifier of the blog.
 * @property {string} title - The title of the blog post.
 * @property {string} author - The author of the blog post.
 * @property {string} content - The content of the blog post.
 * @property {string} created_at - The creation timestamp in ISO format.
 * @property {string[]} tags - Array of tags associated with the blog post.
 */

// Function to fetch all blogs
/**
 * Fetches the latest 10 blogs from Firestore.
 * @returns {Promise<Blog[]>} An array of blog objects.
 */
export const fetchBlogs = async () => {
  const todayString = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

  try {
    const blogsRef = collection(db, "blogs");
    const blogsQuery = query(
      blogsRef,
      where("created_at", "<=", todayString),
      orderBy("created_at", "desc"),
      limit(10)
    );
    const querySnapshot = await getDocs(blogsQuery);

    const blogs = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        title: data.title || "",
        author: data.author || "",
        content: data.content || "",
        created_at: data.created_at || "",
        shortDescription: data.short_description || "",
        tags: data.tags || [],
      };
    });

    return blogs;
  } catch (error) {
    console.error("Error fetching blogs:", error);
    throw new Error("Failed to fetch blogs from Firestore");
  }
};

/**
 * Fetches a single blog post by its ID.
 * @param {string} id - The ID of the blog post to fetch.
 * @returns {Promise<Blog>} A blog object.
 */
export const fetchBlogById = async (id) => {
  try {
    const blogRef = doc(db, "blogs", id);
    const docSnap = await getDoc(blogRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      return {
        id: docSnap.id,
        title: data.title || "",
        author: data.author || "",
        content: data.content || "",
        created_at: data.created_at || "",
        tags: data.tags || [],
      };
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error fetching blog by ID:", error);
    throw new Error("Failed to fetch blog from Firestore");
  }
};
