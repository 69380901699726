import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import AboutOne from "../component/about/AboutOne";
import BannerOne from "../component/banner/BannerOne";
import BlogOne from "../component/blog/BlogOne";
import BrandOne from "../component/brand/BrandOne";
import CounterUpOne from "../component/counterup/CounterUpOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import PricingOne from "../component/pricing/PricingOne";
import ProjectOne from "../component/project/ProjectOne";
import ServicePropOne from "../component/service/ServicePropOne";
import TestimonialOne from "../component/testimonial/TestimonialOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";

const DigitalAgency = () => {
  return (
    <>
      <SEO title="Digital Agency" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BannerOne />
        <div id="services" className="section section-padding-2 bg-color-dark">
          <div className="container">
            <SectionTitle
              subtitle="השירותים שלנו"
              title="איך זה עובד?"
              description="עם השירותים שאנחנו מציעים תוכלו לקבל תובנות על איך לקדם את העסק שלכם , מקום גבוה בגוגל -> יותר לקוחות -> יותר מכירות"
              textAlignment="heading-light-left"
              textColor=""
            />
            <div className="row">
              <ServicePropOne
                colSize="col-xl-4 col-md-6"
                serviceStyle=""
                itemShow="6"
              />
            </div>
          </div>
        </div>
        <TestimonialOne />
        <CounterUpOne />

        <div className="section bg-color-light section-padding" id="prices">
          <div className="container">
            <SectionTitle
              subtitle="מחירון"
              title="בעזרת המוצר שלנו תגיעו רחוק"
              description="אצלנו יש חבילה לכל סוג של עסק , תוכלו לבחור את החבילה שמתאימה לכם ולהתחיל לקבל אנליזות ודוחות שיעזרו לקדם את העסק שלכם"
              textAlignment=""
              textColor=""
            />
            <PricingOne />
          </div>
          <ul className="list-unstyled shape-group-3">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-1.png"}
                alt="shape"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"}
                alt="shape"
              />
            </li>
          </ul>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" id="contact" />
      </main>
    </>
  );
};

export default DigitalAgency;
